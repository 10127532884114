import { Controller } from "@hotwired/stimulus"

function disableElement(someElement) {
  if (someElement.nodeName === "A") {
    someElement.setAttribute("disabled", "disabled")
  } else {
    someElement.disabled = true
  }
}

export default class extends Controller {
  static classes = [ "loading" ]
  static targets = [ "fieldset", "button", "submitButton", "accessibleStatus" ]

  static values = {
    submittingMessage: String
  }

  showAccessibleMessage() {
    if (this.hasSubmittingMessageValue) {
      this.accessibleStatusTarget.innerHTML = this.submittingMessageValue
    }
  }

  markLoading() {
    this.disableFieldsets()
    this.disableButtons()
    this.putSubmitButtonInLoadingMode()
    this.showAccessibleMessage()
  }

  disableFieldsets() {
    this.fieldsetTargets.forEach((fieldsetTarget) => {
      fieldsetTarget.disabled = true
    })
  }

  putSubmitButtonInLoadingMode() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.loadingClass)
    }
  }

  disableButtons() {
    const buttons = this.#getButtons()

    buttons.forEach((buttonTarget) => {
      if (buttonTarget) {
        disableElement(buttonTarget)
      }
    })
  }

  #getButtons() {
    if (this.hasButtonTarget && this.hasSubmitButtonTarget) {
      return [ ...this.buttonTargets, this.submitButtonTarget ]
    } else if (this.hasSubmitButtonTarget) {
      return [ this.submitButtonTarget ]
    } else if (this.hasButtonTarget) {
      return this.buttonTargets
    }

    return []
  }
}